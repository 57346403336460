@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500&display=swap");

* {
  font-family: "Ubuntu", sans-serif;
  padding: 0;
  margin: 0;
}

@tailwind base;

/* Write your own custom base styles here */

/* Start purging... */
@tailwind components;
/* Stop purging. */

/* Write you own custom component styles here */

.ease-in-animation {
  @apply transition duration-500 ease-in-out;
}

/* Start purging... */
@tailwind utilities;
/* Stop purging. */

/* Your own custom utilities */

.default-card {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border: 0px solid !important;
  min-height: 40vh;
}

.featured-title {
  text-align: center;
}

.h1-fix {
  font-size: 2.7rem;
}

.p-size {
  font-size: 1.1rem;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: rgb(250, 250, 250);
  background-clip: padding-box;
  border: none;
  border-radius: 0.25rem;
}

.h2-fix {
  font-size: 2.3rem;
}

.ready-design {
  font-size: 3rem;
}

.h3-fix {
  font-size: 1.6rem;
}

.svg-img {
  width: 10rem;
}

.dash-board-main {
  padding: 2rem;
  background-image: linear-gradient(
      to bottom,
      rgba(122, 122, 122, 0.52),
      rgba(0, 0, 0, 0.733)
    ),
    url("./img/interior.jpeg");
  width: 80%;
  height: 400px;
  background-size: cover;
  color: white;
  margin-bottom: 5vh;
  border-radius: 2rem;
  height: 50vh;
}

.time-dash {
  margin-top: 6vh;
  font-size: 6rem;
}

.upload-preview-image {
  height: 10rem;
  padding: 1rem 2rem;
  width: 100%;
  object-fit: cover;
  border-radius: 0.6rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.upload-preview-image-full {
  height: 10rem;
  width: 100%;
  object-fit: cover;
  border-radius: 0.6rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.input#file-upload-button {
  padding: 20rem;
}

.form-input {
  border-radius: 2rem !important;
  padding: 1.5rem 6rem !important;
}

.login-page {
  background-color: #0e0520;
  height: 100vh;
  padding-top: 25vh;
}
/* BEGINNING OF DESIGN CARD */
.design-card {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border: 0px solid #fff !important;
  border-radius: 1rem !important;
}

.design-card-img {
  border-radius: 1rem !important;

  object-fit: cover;
}

.enlarge {
  min-height: 150vh;
  width: 100vw;
}

/* USER CLASSES */
/* GOOGLE FONTS */
/* @import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;1,100;1,600;1,800&display=swap");

/* Overrides */
/* {
  font-family: "Poppins", sans-serif;
  padding: 0;
  margin: 0;
} */

html,
body {
  height: 100%;
}

body {
  margin: 0;
  overflow-x: hidden;
}

/* Color Variables */
:root {
  --purple: #3d276f;
  --blue: #298aca;
  --deepblue: #0e2231;
  --deeppurple: #0e0520;
}

/* GENERAL STYLES */

.register-card {
  padding-top: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 2rem;
  margin-top: -15vh;
  min-height: 60vh;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border: 0px solid #fff !important;
  border-radius: 1rem !important;
  color: #1d1d1d !important;
}

.react-photo-gallery--gallery > div > img {
  object-fit: cover;
}

.team-card {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border: 0px solid #fff !important;
  border-radius: 1rem !important;
  color: #1d1d1d !important;
  min-height: 60vh !important;
}

.team-card img {
  border-radius: 1rem !important;
}

.choose-headings {
  font-size: 1.7rem;
}

.whatwedo-img {
  height: 16rem;
  width: 100%;
  object-fit: cover;
  border-radius: 1rem;
  margin-bottom: 1rem;
}

.justify {
  text-align: justify;
  text-justify: inter-word;
}

.inner-container {
  padding: 3rem 3rem;
  min-height: 50vh;
}

.section-card {
  width: 100%;
  padding-top: 2rem;
  padding-left: 5rem;
  padding-right: 5rem;
  padding-bottom: 10rem;
  margin-bottom: 10rem;
  margin-top: -10vh;
  min-height: 60vh;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border: 0px solid #fff !important;
  border-radius: 1rem !important;
  color: #1d1d1d !important;
}

/* BEGINNING OF DESIGN CARD */
.design-card {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border: 0px solid #fff !important;
  border-radius: 1rem !important;
  color: #1d1d1d !important;
}

.design-card:hover {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border: 0px solid #fff !important;
  border-radius: 1rem !important;
}

.analytics-number {
  font-size: 6rem;
}

.header-image-single-design {
  background-color: #fff;
  margin: 0;
  padding-left: 0px !important;
  padding-right: 0px !important;
  min-height: 60vh;
}

.loader-area {
  padding-top: 20vh;
  min-height: 50vh;
}

.design-card {
  --color: #ff7f82;
  --hover: #ffdc7f;
}

.design-card:hover,
.design-card:focus {
  box-shadow: rgba(44, 46, 49, 0.199) 0px 8px 24px;
  border: 0px solid #fff !important;
  transform: translateY(-0.35em);
  transition: transform 0.4s ease-in-out;
}

.design-card-img {
  border-radius: 1rem !important;
  height: 15rem;
  background-color: #3d276f;
  object-fit: cover;
}

/* END OF DESIGN CARD */

.landing-slideshow {
  background-color: #3d276f;
  height: 100vh;
}

.filter-bar {
  min-height: 10vh;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
}
.filter-bar-2 {
  min-height: 10vh;
  width: 85vw;
}

.filter-fields {
  border: 0px;
}

.scipture {
  background-color: rgb(235, 235, 235);
}

.purple-header {
  padding-top: 20vh;

  background: linear-gradient(
      125deg,
      hsla(259, 48%, 29%, 0.9),
      hsla(204, 66%, 48%, 0.9)
    ),
    url("./img/home.jpeg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 45vh;
}

.purple-header-2 {
  padding-top: 15vh;
  min-height: 30vh;

  background: linear-gradient(
      125deg,
      hsla(259, 48%, 29%, 0.9),
      hsla(204, 66%, 48%, 0.9)
    ),
    url("./img/home.jpeg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.service-row-first {
  margin-bottom: 8rem;
  margin-top: 5rem;
}

.extra-bottom-spacing {
  margin-bottom: 5rem;
}

.small-bottom-spacing {
  margin-bottom: 2rem;
}

.spacing {
  margin-top: 2vh;
}

.service-row {
  margin-top: 8rem;
}

.Vision-statement {
  font-size: 2rem;
}
.register-purple-header {
  padding-top: 20vh;
  background-color: #3d276f;
  min-height: 50vh;
}

.register-header-image {
  object-fit: cover;
  max-height: 50vh;
  width: 100vw;
}

.img-div {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0.35) 100%
  );
  z-index: -10;
}

.text-div {
  position: absolute;
  z-index: 10;
}

.browse-purple-header {
  padding-top: 15vh;
  background-color: #3d276f;
  min-height: 25vh;
}

.header-text {
  text-align: center;
  align-items: center;
  position: absolute;
  top: 0;
  margin-top: 40vh;
}

a {
  color: #0e0520;
  text-decoration: none; /* no underline */
}
.row-full-height {
  height: 100vh;
}

/* CAROUSEL */
.header-carousel {
  position: top;
}

.min-height-100 {
  min-height: 100vh;
}

.sub-section {
  padding-top: 6rem;
  padding-bottom: 6rem;

  min-height: 50vh;
}

.caro-pos {
  margin-top: -10vh;
  z-index: -1000;
}

.carousel-img {
  object-fit: cover;
  position: relative;
  display: block;
  width: 100vw;
  height: 110vh;
  z-index: -1;
}

.carousel-caption {
  text-align: left;
  width: 100vw;
  height: 100vh;
  left: 0 !important;
  padding-top: 18rem !important;
}

.carousel-caption h1 {
  font-size: 4rem;
  text-transform: capitalize;
}

.carousel-caption h5 {
  word-wrap: break-word;
}

.img-parent {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0.35) 100%
  );
}

.year-print {
  background-color: black;
  color: white;
  padding-top: 1rem;
  padding-bottom: 0.7rem;
}

.padding-extra {
  padding-top: 6vh;
}

.ready-to {
  background: linear-gradient(
      135deg,
      hsla(0, 0%, 0%, 0.911),
      hsla(0, 0%, 9%, 0.212)
    ),
    url("./img/interior.jpeg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 60vh;
  color: #fff;
  text-align: center;
}

.design-card-text {
  font-size: 0.93rem;
}

.mb-auto,
.my-auto {
  margin-bottom: 0rem !important;
}

.row-90-height {
  height: 90vh;
}

.single-design-header-image {
  height: 80vh;
  object-fit: cover;
  width: 100%;
}

.icon-style {
  color: #fff;
}

.choose-rounded {
  padding-top: 5rem;
  background-color: var(--purple);
  border-radius: 3rem;
  margin-left: 3rem;
  margin-right: 3rem;
  height: 15rem;
}

.btn {
  font-weight: 500 !important;
  border-radius: 30px !important;
  padding: 10px 50px 10px 50px !important;
}

.btn-light:hover {
  background-color: var(--blue) !important;
  border: none;
  font-weight: 700;
  border-radius: 30px;
  padding: 10px 50px 10px 50px;
  color: #fff !important;
}

.footer-list li {
  font-size: 1rem;
  list-style: none;
  color: rgb(189, 189, 189);
}

.year {
  font-size: 0.9rem;

  color: rgb(189, 189, 189);
}

.year a {
  color: rgb(189, 189, 189);
}

/* SPECIFIC STYLING */
.maintenance {
  background-color: #fff;
  height: 100vh;
}

.maintenance-row {
  margin-top: 10rem;
}

.footer {
  padding: 4rem 0;
  min-height: 28vh;
  background-color: #0e2231;
}

/* NOT FOUND PAGE */

.not-found .header {
  font-weight: 500;
  font-size: 10rem;
}

.admin-card-img {
  height: 10rem;
  object-fit: cover;
}

.not-found {
  background-color: #fff;
  height: 100vh;
}

.admin-slider {
  height: 80vh;
  border-radius: 1rem;
  object-fit: cover;
  width: 100%;
}

.logo {
  width: 15rem;
}

/* .purchase-col {
  text-align: right;
} */

/*  Small devices (landscape phones, 576px and up) */
@media (max-width: 576px) {
  /* SECTION CARD FOR OTHER PAGES RESET FOR MOBILE */
  .section-card {
    width: 100%;
    padding-top: 1rem;
    padding-left: 0rem;
    padding-right: 0rem;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    margin-top: 1vh;
    min-height: 40vh;
    box-shadow: none;
    border: 0px solid #fff !important;
    border-radius: 0rem !important;
    color: #1d1d1d !important;
  }
  /* END OF RESETS */
  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: rgb(250, 250, 250) !important;
    background-clip: padding-box;
    border: none !important;
    border-radius: 0.25rem;
  }
  .purchase-col {
    text-align: right;
  }
  /* navbar */
  .navbar-collapse {
    background-color: rgb(250, 250, 250) !important;
    padding-left: 2rem;
    margin-top: 0.5rem;
    padding-right: 1rem;
    border-radius: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  /* footer */
  .year {
    font-size: 0.9rem;
    text-align: center;
    color: rgb(189, 189, 189);
  }
  .social-item {
    margin-bottom: 2rem;
    margin-top: 2rem;
  }

  /* header */
  .featured-title {
    text-align: center;
    font-size: 3rem !important;
    padding: 2rem;
    margin-top: -8rem;
  }

  .featured-title-2 {
    text-align: center;
    font-size: 3rem !important;
    padding: 2rem;
  }

  .featured-image-div {
    margin-top: -8rem;
  }

  .ready-design {
    font-size: 2.3rem;
  }

  .footer-col {
    padding-top: 1rem;
    text-align: center;
  }

  a {
    color: inherit !important;
    text-decoration: none !important;
  }
  .link-reset > a {
    color: inherit !important;
    text-decoration: none !important;
  }
  .logo {
    width: 10rem;
  }

  /* NOT FOUND PAGE */

  .not-found .header {
    font-weight: 500;
    font-size: 6rem;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
}

/* ============ desktop view ============ */
@media all and (min-width: 992px) {
  .navbar .nav-item .dropdown-menu {
    display: none;
  }

  .navbar .nav-item:hover .dropdown-menu {
    display: block;
  }
  .navbar .nav-item .dropdown-menu {
    margin-top: 0;
  }
}
/* ============ desktop view .end// ============ */
@media (min-width: 1024px) {
  .btn-menu {
    display: none;
    position: relative;
  }
}
/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
